<template>
  <dash-page-new
      :root="$t('Dashboard')"
      :root-location="businessDashLink('')"
      icon="mdi-robot"
      :title="$t('ChatBots')"
      :subtitle="$t('ChatBots')"
      no-side>
    <template #default>
      <v-sheet class="wsRoundedHalf mt-6">

        <!-- Link Telegram Bot-->
        <div v-if="!telegram">
          <div class="d-flex justify-space-between align-center mb-6">
            <div>
              <h3 style="line-height: 1.2" class="font-weight-medium mb-0 ">{{ 'Telegram' }}</h3>
              <h5  class="font-weight-light "> {{ $t('LinkTelegramBot') }}  </h5>
            </div>
            <v-btn outlined :color="wsACCENT" class="noCaps" @click="displayTelegramHelp = true">
              <v-icon class="mr-2">mdi-information-outline</v-icon>
              {{ $t('Instruction') }}
            </v-btn>
          </div>
          <v-sheet v-if="!telegram" outlined class=" pa-6 wsRoundedHalf">

            <v-tooltip bottom  light  max-width="400" color="#ffffff">
              <template class="d-flex" v-slot:activator="{ on, attrs }">
                <v-sheet class="d-inline-block" v-bind="attrs"  v-on="on">
                  <h5 class="pointer d-flex align-center"  >
                    <v-icon class="mr-1" small :color="wsACCENT">mdi-information-outline</v-icon>
                    {{ $t('TelegramToken') }}
                  </h5>
                </v-sheet>
              </template>
              <v-sheet COLOR="WHITE" sty light class="mx-2 my-3">
                {{ $t('TelegramTokenTooltip') }}
              </v-sheet>

            </v-tooltip>

            <v-text-field   v-model="entityData.token"
                            :label="$t('TelegramToken')"
                            hide-details dense outlined
                            class=" mb-6 mt-3"  />

            <v-btn @click="linkTelegram" class="noCaps" block dark :color="wsATTENTION">{{ $t('LinkTelegramBot') }}</v-btn>

          </v-sheet>
        </div>
        <!-- Telegram Bot Stats-->
        <div v-else>

          <div class="d-flex justify-space-between">
            <div>
              <h3 style="line-height: 1.2" class="font-weight-medium ">{{ 'Telegram' }}</h3>
              <h5  class="font-weight-light "> {{ $t('TelegramLinked') }}  </h5>
            </div>
            <v-btn @click="unlinkTelegram" color="red lighten-2" text class="noCaps">
              <v-icon small class="mr-2">mdi-link-variant-off</v-icon>
              {{ $t('UnlinkBot') }}
            </v-btn>
          </div>
          <v-sheet outlined class="mt-6 pa-6 wsRoundedHalf">
            <div class="d-flex justify-space-between align-center">
              <router-link class="noUnderline black--text" :to="businessDashLink('chatbots/constructor')">
                <div class="d-flex align-center">
                  <v-avatar class="mr-3 pointer" size="64" :color="!telegramBot.img ? wsLIGHTACCENT : ''">
                    <v-img  v-if="telegramBot.img" :src="telegramBot.img"></v-img>
                    <span v-else class="white--text text-h5">{{ telegramBot.first_name.substring(0, 1) }}</span>
                  </v-avatar>
                  <div>
                    <h3 class="font-weight-medium hover-underline pointer"> {{ telegramBot.first_name }}</h3>
                    <h5 class="font-weight-light"> t.me/{{ telegramBot.username }}</h5>
                  </div>
                </div>
              </router-link>


              <div>
                <v-btn :to="businessDashLink('chatbots/constructor')" outlined class="noCaps" :color="wsACCENT">
                  {{  $t('Goto')  }}
                  <v-icon >mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>

            <v-divider class="mt-3" />
            <v-sheet  color="transparent mt-6"  >
              <ws-chart-line v-if="telegramBot.subscribers_month > 0"
                  :title="$t('BotNewClientsMonth')"
                  :total="telegramBot.subscribers_month"
                  :series="telegramBot.month_activity_values"
                  :labels="telegramBot.month_activity_days"
                  :series-name="$t('UsersAmountBotInteractions')"
              />
            </v-sheet>



            <div  class="d-flex justify-space-between mt-6">
              <div>
                <h1 :style="`color : ${wsACCENT}`" class="font-weight-medium text-center">{{ telegramBot.subscribers }}</h1>
                <h5 class="font-weight-light text-center" >{{ $t('Subscribers') }}</h5>
              </div>
              <div>
                <h1 :style="`color : ${wsACCENT}`" class="font-weight-medium text-center">{{ telegramBot.activity_today }}</h1>
                <h5 class="font-weight-light text-center" >{{ $t('TodayActivity') }}</h5>
              </div>
              <div>
                <h1 :style="`color : ${wsACCENT}`" class="font-weight-medium text-center">+{{ telegramBot.subscribers_today }}</h1>
                <h5 class="font-weight-light text-center" >{{ $t('NewToday') }}</h5>
              </div>
              <div>
                <h1 :style="`color : ${wsACCENT}`" class="font-weight-medium text-center">+{{ telegramBot.subscribers_month }}</h1>
                <h5 class="font-weight-light text-center" >{{ $t('Month') }}</h5>
              </div>
            </div>

          </v-sheet>

        </div>

      </v-sheet>
    </template>

    <template #dialog>
      <ws-dialog       v-model="displayTelegramHelp"
                       width="650"
                       no-navigation
                       no-padding >

        <div>

          <v-sheet class="pt-3">
            <v-img class=" mx-auto" height="200" contain   src="@/assets/onboarding/chatbots_botfather.jpeg" />
          </v-sheet>
          <h4 class="font-weight-medium text-center mt-6">
            {{ $t('BotFatherTitle') }}
          </h4>
          <h5 class="font-weight-light text-center mt-1 ">
            {{ $t('BotFatherInstruction') }}
          </h5>

          <div class="d-flex justify-center">
            <v-btn href="https://t.me/BotFather" target="_blank" dark elevation="0"  :color="wsACCENT" class="noCaps mt-6 px-16">t.me/BotFather</v-btn>
          </div>
        </div>
      </ws-dialog>
    </template>
  </dash-page-new>
</template>

<script>
import { mapActions } from "vuex";
import wsChartLine from "@/components/UI/wsChartLine";
import { ONBOARD_CHATBOTS }    from "@/onboardTours/ChatBots/ChatBots" ;
export default {
  name: "BusinessDashboardModules",
  components : {
    wsChartLine
  },
  data() {
    return {
      telegram : false,
      displayTelegramHelp : false,
      entityData : {
        token : '',
        link : '',
        telegramBot : {},
      },
      telegramBot : {},
    }
  },
  methods : {

    ...mapActions('chatbots', [
      'GET_CHATBOTS_STATUS',
      'LINK_TELEGRAM_BOT' ,
      'UNLINK_TELEGRAM_BOT',
    ]),

    async linkTelegram() {
      if ( !this.entityData.token ) {
        return this.notify(this.$t('TelegramTokenNotSet'))
      }
      let result = await this.LINK_TELEGRAM_BOT( this.entityData )
      if ( !result ) { return this.notify(this.$t('WrongTelegramToken'))}
      this.notify(this.$t('TelegramLinkedSuccessfully'))
      await this.initPage()
    },
    async unlinkTelegram() {
      let result = await this.UNLINK_TELEGRAM_BOT( this.entityData )
      if ( !result ) { return this.notify(this.$t('Error'))  }
      this.notify(this.$t('TelegramUnlinked'))
      this.telegram = false
    },
    async initPage() {
      let result = await this.GET_CHATBOTS_STATUS()
      this.telegram = result.telegram
      this.telegramBot = result.telegram_bot
    }

  },
  async mounted() {
   this.initPage()
   ONBOARD_CHATBOTS.init(this)
  }
}
</script>

<style scoped>

</style>